import { UniqueIdentifier } from '@dnd-kit/core';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ExpandLess, ExpandMore, FiberManualRecord, MoreHoriz, Person } from '@mui/icons-material';
import { Badge, Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import React, { CSSProperties, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Project as ProjectType } from '../../../../store/slices/projects';
import { ProjectActions } from '../../actions/ProjectActions';
import { DashboardContext, dashboardContext } from '../../dashboardContext';
import { Tree } from '../utils';

interface IProject {
    id: UniqueIdentifier;
    project: ProjectType;
    depth?: number;
    collapsible?: boolean;
    collapsed?: boolean;
    children?: Tree[];
    onCollapse?: (id: UniqueIdentifier) => void;
    over?: boolean;
    clone?: boolean;
    childs?: number;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
    isSorting || wasDragging ? false : true;

export const Project: React.FC<IProject> = ({
    id,
    project,
    depth = 0,
    collapsed = true,
    onCollapse,
    children,
    over,
    clone,
    childs,
}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {
        attributes,
        isDragging,
        isSorting,
        listeners,
        setDraggableNodeRef,
        setDroppableNodeRef,
        transform,
        transition,
    } = useSortable({
        id: `project-${id}`,
        data: { id: id, type: 'project' },
        animateLayoutChanges,
    });

    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    const handleExpand = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (onCollapse) onCollapse(id);
    };

    const handleBadge = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
    };

    return (
        <Paper
            sx={{
                p: 1,
                '&&': { ml: 5 + depth * 3 },
                '&:hover': { backgroundColor: 'rgba(128,128,128,0.1)', cursor: 'grab' },
                backgroundColor: isDragging || over ? 'rgba(128,128,128,0.1)' : undefined,
                touchAction: 'manipulation',
            }}
            elevation={0}
            ref={(node) => {
                setDraggableNodeRef(node);
                setDroppableNodeRef(node);
            }}
            style={style}
            {...attributes}
            {...listeners}
            onClick={() =>
                navigate({ pathname: `/dashboard/projects/${project.id}`, search: searchParams.toString() })
            }
        >
            <Stack
                direction='row'
                sx={{ position: 'relative', alignItems: 'center', opacity: isDragging ? 0.0 : 1.0 }}
            >
                {Boolean(children?.length) && !clone && (
                    <IconButton
                        size='small'
                        sx={{ position: 'absolute', left: (theme) => theme.spacing(-6) }}
                        onClick={handleExpand}
                        disabled={clone}
                    >
                        {collapsed ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                )}

                <IconButton size='small' disabled={clone} onClick={handleBadge}>
                    {project.users?.length > 0 ? (
                        <Person color={project.color} />
                    ) : (
                        <FiberManualRecord color={project.color} />
                    )}
                </IconButton>
                <Typography
                    variant='button'
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: 360,
                        px: 1,
                    }}
                >
                    {project.title}
                    {` `}
                    {project.id}
                </Typography>
                <Box sx={{ flex: 1 }} />
                <Badge badgeContent={childs} color='success' invisible={!clone}>
                    <ProjectActions project={project} />
                </Badge>
            </Stack>
        </Paper>
    );
};
